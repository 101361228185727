.booking-v1 {
	position: relative;

	@media (min-width:1201px) {
		&:before {
			height: 150px;
			content: "";
			width: 100%;
			background: rgb(0, 0, 0);
			background: linear-gradient(180deg, rgba(0, 0, 0, 0.04310227508972342) 0%, rgba(255, 255, 255, 1) 100%);
			background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
			position: absolute;
		}
	}

	.booking-view {
		padding-top: 100px;
		background-color: var(--booking-view-background-color);

		@media (max-width:767px) {
			padding-top: 70px;
		}
	}

	.required::after {
		content: "*";
		vertical-align: super;
		font-size: var(--font-size-sm);
	}

	.label {
		display: inline-block;
	}

	.cart-details {
		padding: 14px;
		background-color: var(--color-grey2);
		border-radius: var(--btn-border-radius);

		.unit-details {
			margin: 20px 0;
			color: var(--color-grey-dark);

			li {
				padding-left: 30px;
				padding-right: 15px;
				margin-top: 5px;
				position: relative;

				&:before {
					content: "\f00c";
					font-family: "Font Awesome 5 Free";
					-webkit-font-smoothing: antialiased;
					font-weight: 600;
					display: inline-block;
					position: absolute;
					left: 0;
					top: 0;
					color: var(--color-primary);
				}
			}
		}
	}

	.pd-form {
		label {
			font-weight: 500;
		}

	}

	.checks {

		.checkbox {

			label {
				vertical-align: inherit;
				display: unset;
			}
		}

		a {
			color: var(--font-color-main) !important;
		}
	}


	.flow-btn {
		margin-left: 20px;
	}


}

.amount-options-list {
	margin: 20px 0;

	.amount-option-btn {
		.amount-or {
			text-align: center;
			margin: 15px 0;
			width: 100%;
			max-width: 305px;
		}

		.btn-default {
			width: 100%;
			max-width: 305px;
		}
	}

}