.search-result-header-v1 {

	border-radius: 3px;
	padding: 20px 0px;
	width: 100%;

	.sort-row {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.sort-by {

			display: flex;
			align-items: center;

			a {
				color: var(--font-color-main);
			}

			.list-group-item {
				padding: 10px 15px;
			}

			>.box {
				border: none !important;

				.arrow {
					z-index: 2;
				}

				>.body {
					padding: 0;

					.list-group {
						margin-bottom: 0;
					}
				}
			}

			strong {
				width: 45px;
				height: 45px;
				box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 10px 0px;
				text-align: center;
				line-height: 45px;
				font-size: 28px;
				border-radius: 3px;
				border: 1px solid rgba(125, 125, 125, .2);
				margin: -26px -8px 0 0;
				cursor: pointer;

				.fa-sort-amount-down {
					color: #000;
				}
			}

			.sort-item {
				color: var(--color-grey-dark);
				font-size: 12px;
				margin: 5px 0;
				background-color: var(--color-white);
				padding: 10px 12px;
				border: 1px solid #dcdcdc;
				margin-left: -1px;
				display: inline-block;
			}

		}
	}
}