.partners-logo {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-items: center;
	padding: 40px 0;
	gap: 30px 50px;

	img {
		filter: grayscale(1);
		width: auto;
		transition: all .3s ease;
		cursor: pointer;

		&:hover {
			filter: none;

		}
	}
}

.ft-block {
	background-color: #5d785b;
}

.ft-copy {
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media (max-width:550px) {
		flex-direction: column;

		span {
			margin-bottom: 10px;
		}
	}
}

.custom-second {
	.script-col {
		@media (max-width:767px) {
			text-align: center;
		}

		iframe {
			@media (max-width:767px) {
				margin: 0 auto;
				max-width: 150px;
			}
		}
	}
}

.newsletter-bl {
	background-color: #5d785b;
	padding: 30px 0;

	.btm-bdr {
		border-bottom: 1px solid #dee2e6ab;
	}

	.headline {
		font-size: 1rem;
		margin-bottom: 0.5rem;
		font-weight: 500;
		line-height: 1.2;
		font-family: var(--font-family-main);
	}
}

.footer-v1 {

	.main-footer {

		.div.hc-widget.hc-widget-small {
			@media (max-width:767px) {
				text-align: center;
			}
		}

		background-color: #5d785b;
		color: var(--footer-font-color);


		.text-muted {
			color: var(--color-grey-normal) !important;
		}

		.row {
			display: flex;
			justify-content: space-between;

			@media (max-width:767px) {
				display: block;
			}

			.col-custom {
				padding: 0 15px;

				&.ft-logo {
					@media (min-width:768px) {
						position: relative;
						top: -55px;
					}
				}

				.icon-travel {
					margin-bottom: 20px;
					display: block;


					@media (max-width:767px) {
						max-width: 160px;
						margin: 0 auto;
						display: block;
						width: 100%;
					}
				}

				.mob-center {
					@media (max-width:767px) {
						text-align: center;
					}
				}

				.hotel-widget {
					max-width: 149px;
					margin: 0 auto;

					@media (max-width:767px) {
						max-width: 149px;
						margin: 0 auto;
						display: block;
						width: 100%;
					}
				}
			}
		}

		padding-left: 5vw;
		padding-right: 5vw;

		.headline {
			font-size: 20px;
			color: var(--color-white);
			text-transform: uppercase;
			font-family: var(--title-font-family-main);
		}


		.logo {
			max-width: 250px;
			height: auto;
			padding-bottom: 20px;
			width: 100%;
			filter: brightness(0) invert(1);
		}

		.social-icons {
			a {
				width: 50px;
				height: 50px;
				display: inline-block;
				background: #3b5998;
				text-align: center;
				line-height: 50px;
				color: #fff;
				border-radius: 50%;

				&.bg-red {
					background-color: #f04d60;
				}
			}
		}


		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);
				}

				.fa {
					font-size: 20px;
					color: var(--footer-font-color);

				}
			}

		}




		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #ffc371;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}

		.paymentmethods {
			width: 100%;
			text-align: right;
			margin-top: 30px;

			.fa {
				color: var(--color-white);
				font-size: 2.3rem;
				padding-left: 10px;

			}
		}
	}

	.copyright {
		background-color: var(--footer-copyright-bg-color);
		color: var(--footer-copyright-font-color);

		a {
			color: var(--footer-copyright-font-color);
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		.v-office-logo {
			float: left;
			padding-right: 20px;

			img {
				width: 50px;
			}
		}
	}

}