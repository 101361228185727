.navbar-v1.affix {
	box-shadow: 0 3px 3px -3px grey;
	background-color: var(--nav-bg-color);

	.bdr-btn {
		a {
			border-color: var(--color-secondary) !important;
		}
	}
}

.noaffix {
	.navbar-v1 {
		position: absolute;
	}
}

/*.inner-navbar {

	.navbar-v1 {
		&.affix-top {
			.main-menu>ul>li>a {
				color: var(--main-nav-font-color) !important;
			}

			.bdr-btn {
				a {
					border-color: var(--color-secondary) !important;

					&:hover {
						color: var(--color-white) !important;
					}
				}
			}
		}
	}
}*/

.navbar-v1.affix,
.navbar-v1 {

	position: fixed;
	width: 100%;
	top: 0;
	border-width: 0 0 1px;
	padding: 0;
	z-index: 50;


	@media (max-width:1200px) {
		position: absolute;
	}

	.input-search-unit {
		padding: 5px 15px;
		max-width: 280px;

		@media (max-width:1200px) {
			max-width: 100%;

		}

		@media (max-width:1200px) {
			margin-bottom: 5px;
		}

		.form-control {
			padding: 5px 10px;
			width: 200px;

		}

		.form-control::placeholder {
			font-size: 0.875rem;
			color: #aaa;
			font-style: italic;
		}

		.btn {
			border-radius: 0 5px 5px 0;
			padding: 0px 15px;
		}
	}

	/*top-nav {
		padding: 3px 0 8px;
		text-align: right;
		font-size: var(--top-nav-font-size);
		font-family: var(--bodyfont);
		background-color: var(--top-nav-bg-color);

		ul {
			padding: 0;
			margin: 0;
			display: inline-flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: flex-end;

			li {
				display: inline-block;
				position: relative;
				font-size: var(--top-nav-font-size);
				color: var(--top-nav-font-color);

				@media (max-width:1200px) {
					display: block;
				}

				a {
					color: var(--top-nav-font-color);
					font-size: var(--top-nav-font-size);
					text-decoration: none;
					padding: 5px 15px;

					@media (max-width:550px) {
						padding: 5px 4px;
					}

					&:hover {
						color: var(--top-nav-font-color-hover)
					}

					i.fa {
						font-size: var(--top-nav-icon-size);
						margin-right: 5px;
					}
				}
			}
		}

		.fav-count {

			.sub-count {
				-moz-border-radius: 50%;
				-webkit-border-radius: 50%;
				border-radius: 50%;
				display: block;
				position: absolute;
				top: -5px;
				left: 2px;
				width: 18px;
				height: 18px;
				background: var(--color-primary);
				color: var(--color-white);
				text-align: center;
				font-size: 12px;
				line-height: 18px;
			}
		}

	}*/

	.header-main {

		.header-logo {
			position: absolute;
			top: 10px;
			z-index: 20;

			.logo {
				width: 150px;
				height: auto;

				@media (max-width:1200px) {
					width: 150px;
				}

				@media (max-width:768px) {
					width: 110px;
				}
			}

		}

		.main-menu {
			float: right;
			margin: 40px 0;



			@media (max-width:1200px) {
				position: absolute;
				left: 0;
				top: 100%;
				margin: 0;
				background-color: var(--main-nav-mob-menu-bg-color);
			}

			>ul {
				padding: 0;
				margin: 0;

				>li {
					float: left;
					display: inline-block;

					padding: 10px 0;
					border-bottom: 2px solid transparent;

					@media (min-width:1201px) {
						margin-left: 18px;
					}

					@media (max-width:1200px) {
						padding: 0;
					}

					&:hover {
						border-bottom: 2px solid var(--color-primary-light);

						a {
							text-decoration: none;
						}

					}

					&.bdr-btn {
						border: none !important;
						padding: 0;

						@media (max-width:1200px) {
							padding: 0 15px;
						}


						a {
							border: 1px solid var(--color-white);
							border-radius: 20px;
							padding: 10px 15px;

							@media (max-width:1200px) {
								display: inline-block;
								position: relative;
								margin-top: 6px;
								margin-bottom: 10px;
							}

							&:hover {
								border: 1px solid var(--color-secondary);
								background-color: var(--main-nav-bg-hover);
								color: var(--main-nav-font-color-hover);
							}

						}


					}

					@media (max-width:1200px) {
						display: block;
						width: 100%;
					}

					&.bdr-btn {
						border: 1px solid var(--color-white);
					}


					>a {
						color: var(--main-nav-font-color);
						text-transform: var(--main-nav-text-transform);
						font-size: var(--main-nav-font-size);
						line-height: 1;
						display: block;
						font-weight: 400;

						@media (max-width:1199px) {
							font-size: var(--main-nav-font-size);
							color: var(--color-white);
						}

						@media (max-width:1200px) {
							padding: 10px 15px;
							text-transform: capitalize;
							font-size: var(--main-nav-font-size);
							color: var(--main-nav-mob-font-color-hover);
						}


					}

					&.dropdown {
						cursor: pointer;

						.dropdown-backdrop {
							display: none;
						}


					}




				}
			}

			.btn {
				@media (max-width:340px) {
					font-size: 12px;
					padding: 8px 8px;
				}
			}
		}
	}

	.dropdown-menu {
		border-radius: var(--btn-border-radius);
		border: none;
		box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
		min-width: 300px;
		margin-top: 10px;
		background-color: var(--color-grey1);

	}

	.sub-nav {

		ul {
			list-style-type: none;
			padding-left: 10px;

			li {

				a {
					padding: 10px 0;
					color: var(--main-sub-font-color) !important;
					display: block;
					font-size: 17px;

					@media (max-width:992px) {
						padding: 10px;
						font-weight: 400;
						text-transform: initial;
					}

					&:hover {
						color: var(--main-sub-font-color-hover) !important;
						text-decoration: none;

					}
				}

			}
		}


	}

	.icon {
		display: none;
		position: absolute;
		right: 10px;
		text-decoration: none;
		color: var(--color-secondary);
		font-size: 30px;

	}

	.navactive {
		@media (min-width:1201px) {
			color: var(--main-nav-font-color-hover) !important;
			background-color: var(--main-nav-bg-hover) !important;
		}
	}


	@media screen and (max-width: 1200px) {

		.top-nav {
			padding: 10px 0 8px;
			text-align: right;


			.input-search-unit {
				display: none;
			}
		}

		.header-logo {
			padding-left: 0px !important;
		}

		.nav-row {
			min-height: 110px !important;
		}

		.icon {
			display: block;
			bottom: 30px;
		}

		.dropdown-menu {
			position: relative !important;
			transform: none !important;
			width: 100%;
			border-top: none;
		}

		.main-menu {
			background-color: var(--nav-bg-color);
			padding: 5px 0;
			z-index: 50;
			display: none;
			width: 100%;
			border-bottom: 1px solid #61847a;
		}

		.search-unit-ul {
			margin: 15px 0px 0px;

			.input-search-unit {
				.input-group {
					.form-control {
						@media (max-width: 574px) {
							height: 40px !important;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 768px) {

		.top-nav {
			text-align: center;

			.input-search-unit {
				display: none;
			}
		}

		.header-main {

			.header-logo {
				position: static;
				text-align: center;

			}

			.nav-row {
				min-height: 5px !important;
			}

			.icon {
				position: absolute;
				bottom: 20px;
				right: 10px;
			}
		}

	}

	@media screen and (min-width: 1201px) {
		.main-menu {
			display: block !important;
		}
	}

	&.affix-top {
		border-bottom: var(--nav-border-bottom-affix-top);
		background-color: var(--nav-bg-color-affix-top);
		z-index: 4;

		@media (max-width:1200px) {
			background-color: var(--color-white);
		}

		.top-nav {
			background-color: var(--top-nav-bg-color-affix-top);

			.input-search-unit {
				@media (max-width:1200px) {
					display: none;
				}
			}

			ul {

				li {
					color: var(--top-nav-font-color-affix-top);

					@media (max-width:1200px) {
						display: block;
					}

					a {
						color: var(--top-nav-font-color-affix-top);
						font-size: var(--top-nav-font-size);
						text-decoration: none;
						padding: 5px 15px;

						@media (max-width:550px) {
							padding: 5px 4px;
						}

						&:hover {
							color: var(--top-nav-font-color-affix-top-hover);
						}

					}
				}
			}
		}



		.header-main {

			.header-logo {

				.logo {}

			}

			.main-menu {

				>ul {
					padding: 0;
					margin: 0;

					@media (min-width:1201px) {
						display: flex;
						align-items: center;
					}

					>li {
						border-bottom: 2px solid transparent;

						&:hover {
							border-bottom: 2px solid var(--color-primary-light);

							a {
								color: var(--main-nav-font-color-affix-top-hover);
								text-decoration: none;
							}
						}

						>a {
							color: var(--main-nav-font-color-affix-top);

							@media (max-width: 1200px) {
								color: var(--color-white);
							}
						}


						&.dropdown {

							cursor: pointer;

							.dropdown-backdrop {
								display: none;
							}

						}

					}
				}

				.btn {
					@media (max-width:340px) {
						font-size: 12px;
						padding: 8px 8px;
					}
				}
			}
		}
	}

}

.mobil-menu {
	position: fixed;
	cursor: pointer;
	display: none;
	bottom: 20px;
	right: 5px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: #fff;
	z-index: 200;
	line-height: 40px;
	text-align: center;
	font-size: 24px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

	@media (min-width:1101px) {
		display: none !important;
	}
}

.open-by-mob {
	#mob-nav-icon {
		display: none;
	}

}