.contact-form-v1 {
	padding-bottom: 62px !important;
	background-color: var(--color-grey2);
	border-radius: var(--btn-border-radius);

	.checkbox label {
		vertical-align: inherit;
	}

	.red {
		color: var(--color-red);
	}
}