.unit-title-v1 {
	.stars {
		color: var(--color-stars);
		font-size: var(--font-size-md);
		vertical-align: top;
		display: inline-block;
	}

	h1 {
		display: flex;
	}

	.star-col {
		display: flex;
		margin-left: 10px;

		.fa-star-o {
			display: none
		}

		.dtv-text {
			font-size: 14px;
			margin-left: 5px;
		}
	}
}