.unit-page-layout-v1 {


	.unit-title-v1 {
		@media(max-width:768px) {
			padding-top: 24px;
		}
	}

	#accordion {
		.widget {
			.accord-head {
				>a {
					position: relative;
					text-decoration: none;

					.widget-header {
						border-radius: 10px;
					}

					&[aria-expanded="true"] {
						&:after {
							transform: rotate(180deg);
						}

						.widget-header {
							border-radius: 10px 10px 0 0;
						}
					}


					&:after {
						content: "\f107";
						font-family: "Font Awesome 5 Free";
						font-weight: 900;
						position: absolute;
						right: 16px;
						top: 26px;
						font-size: 25px;
						transform: rotate(0deg);
						line-height: 1;
						transition: all .2s ease-in-out;

						@media (max-width:992px) {
							top: 16px;
						}
					}

				}
			}

		}
	}

	.unit-banner {
		height: calc(75vh - 80px);
		position: relative;

		@media (min-width:1201px) {
			&:before {
				height: 150px;
				content: "";
				width: 100%;
				background: rgb(0, 0, 0);
				background: linear-gradient(180deg, rgba(0, 0, 0, 0.04310227508972342) 0%, rgba(255, 255, 255, 1) 100%);
				background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
				position: absolute;
			}
		}

		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	.left-col {
		padding: 0px 15px;

		@media(max-width: 1200px) {
			width: 100% !important;
		}

		@media(min-width: 1201px) {
			width: 68% !important;
		}


		@media(min-width: 1500px) {
			width: 70% !important;
		}
	}

	.right-col {

		padding: 0px 15px;

		@media(max-width: 1200px) {
			display: none;
		}

		@media(min-width: 1201px) {
			width: 32% !important;
		}


		@media(min-width: 1500px) {
			width: 30% !important;
		}
	}


	.booking-hint {
		font-size: var(--font-size-md);
		margin-top: -20px;
		padding-bottom: 10px;
	}

	.unit-book-now-v3 {
		@media(min-width:1200px) {
			background-color: var(--color-grey2);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			display: none;
			margin-bottom: 57px;
			border-radius: var(--btn-border-radius);
		}

		&.is-visible {
			@media(min-width:992px) {
				display: block;
			}
		}
	}

	.unit-book-now-v4 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			margin-bottom: 57px;
		}

	}

}