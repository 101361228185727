.static-v1 {
	margin-top: var(--page-margin-top);

	.heading1 {
		&:after {
			left: 0;
			transform: inherit;
		}
	}

	.inner-banner {
		&.regional {
			background-image: url(RESOURCE/img/banner-essen.webp);
			//background-position: center top;
		}

		&.ausflugs_new {
			background-image: url(RESOURCE/img/ausflugsziele_banner-new.jpg);

			@media (max-width:767px) {
				background-image: url(RESOURCE/img/banner-ausflugsziele-720.webp);
			}
		}

		&.uberuns {
			background-image: url(RESOURCE/img/banner-ueberuns.webp);

			@media (max-width:767px) {
				background-image: url(RESOURCE/img/banner-uberuns-720.webp);
			}
		}

		&.unser-hof {
			background-image: url(RESOURCE/img/inner-unser-hof.jpg);
		}



		&.nordfries {
			background-image: url(RESOURCE/img/banner-nordfrieland.jpg);
		}

		&.weltnature {
			background-image: url(RESOURCE/img/banner-weltnature.jpg);
		}

		&.unser-bet {
			background-image: url(RESOURCE/img/banner-unserbetrieb.jpg);
		}

		&.spiel {
			background-image: url(RESOURCE/img/banner-spiel.webp);
			background-position: center top;
		}

		&.tiere {
			background-image: url(RESOURCE/img/inner-banner-tier-new.jpg);
			background-position: center top;
		}

		&.erholung {
			background-image: url(RESOURCE/img/inner-banner-erholung-new.jpg);
		}

		&.activity {
			background-image: url(RESOURCE/img/inner-banner-activity1-new.jpg);
			background-position: center;
		}

		&.ausflugs {
			background-image: url(RESOURCE/img/inner-banner-ausflugs.jpg);


		}

		&.festland {
			background-image: url(RESOURCE/img/inner-banner-festland.jpg);
		}

		&.wohnen {
			background-image: url(RESOURCE/img/banner-ferienwohnungen.webp);

			@media (max-width:767px) {
				background-image: url(RESOURCE/img/banner-ferienwohnungen-720.webp);
			}

		}

		&.lecker {
			background-image: url(RESOURCE/img/inner-banner-lecker.jpg);
		}

		&.family {
			background-image: url(RESOURCE/img/banner-family-new.jpg);

			@media (max-width:767px) {
				background-image: url(RESOURCE/img/banner-family-720.webp);
			}
		}

		.copyright-row {
			position: absolute;
			right: 15px;
			bottom: 15px;
			background: rgb(255 255 255/70%);
			font-size: 15px;
			padding: 5px 10px;
		}
	}

	&.Ausflugsziele-v1 {
		.btn-bl:after {
			content: "\f107";
			margin-left: 5px;
			font-family: fontawesome;

		}

		.up-arrow:after {
			content: "\f106";
			margin-left: 5px;
			font-family: fontawesome;
		}

		.btn-bl {
			display: inline-flex;

			.lese {
				display: none;
			}

			&.up-arrow {
				.lese {
					display: block;
				}

				.more {
					display: none;
				}
			}
		}

		/*.card-header {
			background-color: var(--widget-header-bg-color);
			border: 0;

			.btn-link {
				font-size: var(--h3-font-size);
				color: var(--widget-header-title-font-color);
			}
		}

		.card-body {
			background: #e3dfd8;
			border-top: 1px solid #969779;
		}*/
		.imagegallery {
			padding-top: 70%;

		}
	}
}