.themes-v1 {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-auto-rows: minmax(150px, auto);
	grid-gap: 1.6em;
	grid-template-areas:
		'img1 img1 img2 img2 img3 img3'
		'img1 img1 img2 img2 img3 img3';


	@media (max-width: 992px) {
		display: flex;
		flex-direction: column;
	}

	a {
		text-decoration: none
	}


	.img1 {
		grid-area: img1;
		background-image: url(RESOURCE/img/demo3-familie.jpg);
	}

	.img2 {
		grid-area: img2;
		background-image: url(RESOURCE/img/demo3-strandkorb.jpg);
	}

	.img3 {
		grid-area: img3;
		background-image: url(RESOURCE/img/demo3-hund.jpg);
	}

	.img4 {
		grid-area: img4;
		background-image: url(RESOURCE/img/grid-segeln.jpg);
	}

	.img5 {
		grid-area: img5;
		background-image: url(RESOURCE/img/grid-luftbild.jpg);
	}

	[class^='theme-card'] {
		color: white;
		text-decoration: none;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		padding: 10px;


		@media (max-width: 992px) {
			height: 35vh;
		}


		&:hover {
			text-decoration: none;
		}

		h2 {
			font-size: var(--theme-card-title);
			color: var(--theme-card-text-color);
		}

		p {
			font-size: var(--theme-card-description);
			color: var(--theme-card-text-color);
		}

		a {
			text-decoration: none;
		}
	}

	.theme-card-1 {
		min-width: 100px;
		min-height: 100px;
		position: relative;
		display: block;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.55);
		background-blend-mode: multiply;
		transition: ease-out 0.5s;
	}

	.theme-card-2 {
		min-width: 100px;
		min-height: 100px;
		position: relative;
		width: 100%;
		height: 100%;
		color: white;
		text-decoration: none;
		font-size: 1.2em;
		transition: ease-out 0.5s;

		display: flex;
		align-items: center;
		justify-content: center;
	}



	.theme-card-3 {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding: 0;
		border-radius: var(--btn-border-radius);
		overflow: hidden;
	}

	.theme-card-3--description-container {
		background-color: var(--overlay-color);
		padding: 10px;
		transition: 1s;
	}



	@media (min-width: 992px) {
		.theme-card-1--description-container .description {
			opacity: 0;
			transition: ease-out 0.5s;
		}

		.theme-card-1:hover .description {
			opacity: 1;
			transition: ease-in 0.5s;
		}

		.theme-card-1:hover {
			background-color: rgba(0, 0, 0, 0.55);
			background-blend-mode: multiply;
			transition: ease-in 0.5s;
		}

		.theme-card-1:not(hover) {
			background-color: initial;
			background-blend-mode: multiply;
			transition: ease-out 0.5s;
		}

		.theme-card-2:hover {
			background-color: var(--overlay-color);
			background-blend-mode: multiply;
			transition: ease-in 0.5s;
		}

		.theme-card-2:not(hover) {
			background-color: initial;
			background-blend-mode: multiply;
			transition: ease-out 0.5s;
		}

		.theme-card-3--description-container {
			height: 15%
		}

		.theme-card-3--description-container .title {
			text-align: center;
			padding: 0px 10px;
		}

		.theme-card-3--description-container .description {
			padding: 30px;
		}

		.theme-card-3:hover .theme-card-3--description-container {
			height: 100%;
			transition: 1s;
		}

		.theme-card-3 .theme-card-3--description-container>p {
			opacity: 0;
			display: none;
		}

		.theme-card-3:hover .theme-card-3--description-container>p {
			opacity: 1;
			transition: 1s ease-in;
			display: block;
		}
	}

	@media (max-width: 991px) {

		.theme-card-1--description-container,
		.theme-card-2--description-container,
		.theme-card-3--description-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			padding: 30px;

		}
	}
}

.theme-grid {

	.item-grid {


		.tile-item {
			position: relative;
			display: block;
			text-align: left;
			height: 350px;
			margin-bottom: 30px;
			width: 100%;
			border-radius: 10px;
			overflow: hidden;

			@media (min-width:993px) {
				height: 450px;
			}

			&.big {
				@media (min-width:993px) {
					height: 600px;
				}
			}

			&:after {
				content: '';
				width: 100%;
				height: 100%;
				background: linear-gradient(rgba(119, 131, 119, 0) 0%, black 100%);
				display: block;
				z-index: 1;
				position: absolute;
				top: 0;
				left: 0;
				opacity: .4;
				transition: all .4s ease;
			}



			.tile-img {
				position: relative;
				height: 100%;
				width: 100%;

				img {
					object-fit: cover;
					width: 100%;
					height: 100%;
				}

			}

			.tile-content {
				position: absolute;
				bottom: 44px;
				z-index: 2;
				width: calc(100% - 100px);
				color: #fff;
				left: 50px;

				.tile-heading {
					font-size: 30px;
					padding-bottom: 20px;
					margin: 0;
					line-height: 1.2;
					color: var(--color-white);
					text-align: left;

					&:after {
						content: '';
						width: 70%;
						height: 1px;
						background: #fff;
						position: relative;
						top: 20px;
						display: block;
						opacity: 1;
						transition: opacity .2s ease;
						transition-delay: 0.8s;
					}
				}

				.tile-para {
					max-height: 0;
					overflow: hidden;
					transition: all 1s ease;

					p {
						margin-bottom: 0;
					}
				}

				.read-more {
					margin-top: 30px;
					border-top: 1px solid #fff !important;
					color: #fff;
					font-size: 1.25rem;
					padding-left: 0;
					padding: 12px 200px 0 0;
					height: auto;
					text-align: left;
					display: inline-block;
				}
			}

			&:hover {
				.tile-para {
					max-height: 400px;
				}

				&:after {
					opacity: .7;
				}

				.tile-heading {
					&:after {
						opacity: 0;
						transition-delay: 0s;
					}
				}
			}
		}
	}
}